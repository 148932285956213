<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header header-elements-inline">
        <legend class="font-weight-semibold text-uppercase font-size-sm">
          <i class="icon-file-text2"></i>
          Enter Nozzle information
        </legend>

        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload"></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>

      <div class="card-body">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input type="text" id="txtname" class="form-control" placeholder="Nozzle Name" v-if="nozzle" v-model="nozzle.name" autocomplete="off">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Tank:</label>
          <div class="col-md-3">
            <select data-placeholder="Select your state" class="form-control" v-if="nozzle" v-model="nozzle.tank_id" >
              <option v-for="group in tanks" v-bind:value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <label class="col-md-3 col-form-label">Open Reading:</label>
          <div class="col-md-4">
            <input type="number" class="form-control" placeholder="Nozzle Serial No" v-if="nozzle" v-model="nozzle.openreading">
          </div>

        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="saveDocument">Save <i class="icon-paperplane ml-2"></i></button>
        </div>

      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'NozzleForm',
  data () {
    return {
      tanks:[],
      nozzle: JSON.parse('{"id":0,"name":"","tank_id":0,"brn_id":0,"item_id":0,"tank":{"id":0,"name":"","outlet":{"id":0,"status":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","email":""},"item":{"id":0,"name":"","hsn":"","taxrate":0,"salerate":0,"porder":0,"ldgr":0},"dipchart":{"id":0,"name":"","list":[]},"capacity":0,"openstock":0,"opendip":0},"openreading":0,"currentreading":0}')
    }
  },
  store,
  component: {

  },
  props: {
    mynozzle: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"name":"","tank_id":0,"brn_id":0,"item_id":0,"tank":{"id":0,"name":"","outlet":{"id":0,"status":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","email":""},"item":{"id":0,"name":"","hsn":"","taxrate":0,"salerate":0,"porder":0,"ldgr":0},"dipchart":{"id":0,"name":"","list":[]},"capacity":0,"openstock":0,"opendip":0},"openreading":0,"currentreading":0}')
    }
  },
  beforeMount () {
    this.nozzle = this.mynozzle; // save props data to itself's data and deal with it
  },
  created () {
    this.$data.nozzle = JSON.parse('{"id":0,"name":"","tank_id":0,"brn_id":0,"item_id":0,"tank":{"id":0,"name":"","outlet":{"id":0,"status":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","email":""},"item":{"id":0,"name":"","hsn":"","taxrate":0,"salerate":0,"porder":0,"ldgr":0},"dipchart":{"id":0,"name":"","list":[]},"capacity":0,"openstock":0,"opendip":0},"openreading":0,"currentreading":0}')
  },
  mounted () {
    $('.form-control-select2').select2();
    this.loadTanks();
    $('#txtname').focus();
  },
  methods: {
    closeWindow () {
      this.$emit('nozzle_window_closed');
    },
    loadTanks () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.tanks = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/tanks/active`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if(_.isArray(resp.data)){
            self.$data.tanks = resp.data;
          }

        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    saveDocument () {
      const self = this;

      if (self.$data.nozzle.name.trim().length == 0) {
        alert('Invalid Name');
        return;
      } else if (self.$data.nozzle.tank_id == 0) {
          alert('Invalid Tank');
          return
      }

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });


      self.$data.nozzle.brn_id =  parseInt(self.$store.state.user.branch.id);
      self.$data.nozzle.openreading = parseInt(self.$data.nozzle.openreading);

      const requestOptions = {
        method: (self.$data.nozzle.id == 0 ? 'POST' : 'PUT'),
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.nozzle)
      }

      fetch(`${process.env.VUE_APP_ROOT_API}api/xpos/nozzle`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          swal({ title: 'Success!', text: resp.msg, type: 'success' , onClose: () => { $('#txtname').focus()}, timer:1500 });
          self.nozzle = JSON.parse('{"id":0,"name":"","tank":{"id":0,"name":"","outlet":{"id":0,"status":0,"name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","email":""},"item":{"id":0,"name":"","hsn":"","taxrate":0,"salerate":0,"porder":0,"ldgr":0},"dipchart":{"id":0,"name":"","list":[]},"capacity":0,"openstock":0,"opendip":0},"openreading":0,"currentreading":0}');
          self.$emit('nozzle_saved',resp.data);
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtname').focus()}, timer:3000 });
      }).finally(()=>{
        $('#mycard').unblock();
      });

    }
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}
</style>
